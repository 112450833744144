<template>
  <div class="orderRefund">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>退款订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <div>
        <span>订单号：</span>
        <el-input v-model="list.coding" placeholder="请输入订单号"></el-input>
      </div>
      <div>
        <span>商品名称：</span>
        <el-input
          v-model="list.prodName"
          placeholder="请输入商品名称"
        ></el-input>
      </div>
      <div>
        <span>联系人：</span>
        <el-input v-model="list.linkMan" placeholder="请输入联系人"></el-input>
      </div>
      <div>
        <span>联系电话：</span>
        <el-input v-model="list.phone" placeholder="请输入电话"></el-input>
      </div>
      <div>
        <span>订单状态:</span>
        <el-select
          v-model="list.status"
          clearable
          @clear="onClear(2)"
          placeholder="请选择订单类型"
        >
          <el-option
            v-for="item in statusOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>订单类型:</span>
        <el-select
          v-model="list.orderType"
          clearable
          @clear="onClear(1)"
          placeholder="请选择订单类型"
        >
          <el-option
            v-for="item in orderType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="time">
        <span>申请时间：</span>
        <el-date-picker
          @change="onChange"
          v-model="payTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div>
        <el-button class="btn" type="primary" @click="getOrderList()"
          >查找</el-button
        >
        <el-button class="btn" type="primary" @click="createForm()"
          >生成报表</el-button
        >
        <el-input style="opacity: 0" placeholder=""></el-input>
      </div>
    </div>
    <el-table :data="orderList" style="width: 100%" border>
      <el-table-column
        align="center"
        prop="coding"
        label="订单编码"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="prodName"
        label="商品名称"
        width="300"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="下单时间"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="beginTime"
        label="	游玩时间"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="linkMsg"
        label="	联系人"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="payWay"
        label="支付方式"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="refundTime"
        label="	退款时间"
        width="width"
      ></el-table-column>

      <el-table-column
        align="center"
        prop="refundMsg"
        label="	退款信息"
        width="width"
      ></el-table-column>
      
      <el-table-column
        align="center"
        prop="userNickName"
        label="下单人"
        width="width"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="width">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            @click="onOrderdetail(row.coding)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { refundOrder, createRefundForm } from "../../api/order";
export default {
  name: "orderRefund",
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      orderType: [
        { value: 1, label: "酒店" },
        { value: 2, label: "门票" },
        { value: 3, label: "线路" },
        { value: 4, label: "万旅卡" },
      ],
      statusOption: [
        { value: 0, label: "全部" },
        { value: 6, label: "申请退款" },
        { value: 7, label: "退款完成" },
      ],
      list: {
        currentPage: 1,
        pageSize: 10,
        coding: "",
        orderType: null,
        prodName: "",
        linkName: "",
        phone: "",
        beginTime: "",
        endTime: "",
        status: null,
      },
      payTime: [],
      orderList: [],
      pagination: {},
    };
  },
  created() {
    this.getOrderList();
  },
  methods: {
    async createForm() {
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      const { data } = await createRefundForm({
        coding: this.list.coding,
        orderType: this.list.orderType,
        prodName: this.list.prodName,
        linkName: this.list.linkName,
        phone: this.list.phone,
        beginTime: this.list.beginTime,
        endTime: this.list.endTime,
        status: this.list.status,
      });
      let blob = new Blob([data], { type: "application/vnd.ms-excel" });
      let downloadElement = document.createElement("a");
      let href = URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = "退款订单报表";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
      loading.close()
    },
    onChange(value) {
      this.getOrderList()
      if (value === null) {
        this.list.beginTime = null;
        this.list.endTime = null;
      } else {
        this.list.beginTime = this.payTime[0];
        this.list.endTime = this.payTime[1];
      }
    },
    onClear(type) {
      if (this.type == 1) {
        this.list.orderType = null;
      } else if (this.type == 2) {
        this.list.status = null;
      }
    },
    //获取订单列表
    async getOrderList() {
      const { data } = await refundOrder(this.list);
      if (data.code == 0) {
        this.orderList = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message.error(data.msg);
      }
    },
    //查看详情
    onOrderdetail(coding) {
      this.$router.push("/refundOrderdetail/" + coding);
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getOrderList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getOrderList();
    },
  },
};
</script>
<style lang="less" scoped>
.orderRefund {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      margin-top: 20px;
      // width: 25%;
      display: flex;
      align-items: baseline;
      .el-date-editor {
        width: 300px;
      }
      .btn {
        margin-left: 20px;
      }
    }
    .el-select {
      width: 200px;
    }
    span {
      margin: 0 10px 0 20px;
    }
    .el-input {
      width: 200px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>